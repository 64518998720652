import { useState } from "react";
import PropTypes from "prop-types";
import { Form } from "antd";
import { withTheme } from "styled-components";
import FormConsultationSmall from "./small";
import FormConsultationLarge from "./large";
import { setGoogleAdwordsInfo } from "../../../utils/helper";
import { getQueryFromStorage } from "../../../hooks/useQueryCatcher";
import { useCookies } from "react-cookie";
import useFetch from "../../../../ClassyKitchen/hooks/useFetch";
const EMAIL_URL = "/api/contact/email";
const CRM_URL = "/api/contact";
import TagManager from "react-gtm-module";
import { eventFormSubmission } from "../../../services/analytics/ga4CustomEvents";
import ModalContactSuccess from "../../Modals/ModalContactSuccess";

const FormConsultation = ({ theme, variant, referralCode, submitButton, dispatchDestination = "contact", formId, darkLabels, handleSubmit: parentHandleSubmit, loading: parentLoading, form: parentForm, showModal: parentShowModal, setShowModal: parentSetShowModal }) => {
  const { submitMultiple } = useFetch();
  const [cookie, setCookie] = useCookies(["converted"]);
  const [form] = Form.useForm();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values) => {
    setLoading(true);
    let url = typeof window !== "undefined" ? window.location.href : "null";
    
    const nameParts = values.full_name?.split(' ') || [];
    const firstName = nameParts[0] || '';
    const lastName = nameParts.slice(1).join(' ') || '';

    // Get user's actual location from localStorage
    let userLocation = { city: '', state: '' };
    try {
      const storedLocation = localStorage.getItem('usersLocationData');
      if (storedLocation) {
        userLocation = JSON.parse(storedLocation);
        console.log('Using user location:', userLocation);
      }
    } catch (error) {
      console.error('Error reading user location:', error);
    }

    // Base data structure
    const baseData = {
      ...values,
      first_name: firstName,
      last_name: lastName,
      client: 'classyclosets',
      queries: getQueryFromStorage(),
      user_id: window.uniqueVisitorId,
      visitorId: window.uniqueVisitorId,
      confidenceScore: window.confidenceScore,
      fingerprint: window.uniqueVisitorId,
      // User's actual location
      user_city: userLocation.city,
      user_state: userLocation.state,
      // For backward compatibility, keep these fields
      city: userLocation.city,
      state: userLocation.state
    };

    // Add showroom location data only for non-franchise forms
    if (dispatchDestination !== 'franchise') {
      // Get showroom location from selected option
      const [showroomCity, showroomState] = values.showroom.split(', ');
      baseData.showroom_city = showroomCity;
      baseData.showroom_state = showroomState;
    }

    // Add override recipient for franchise forms
    if (dispatchDestination === 'franchise') {
      baseData.override_recipient = 'ryan.underdown@classycompanies.com';
    }

    console.log('Form submission data:', baseData);

    setGoogleAdwordsInfo(values);
    TagManager.dataLayer({
      dataLayer: {
        crmOrClassic: "classic",
      },
    });

    try {
      if (dispatchDestination === 'franchise') {
        console.log('Sending franchise form to franchise endpoint:', baseData);
        const response = await fetch('/api/franchise', {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            ...baseData,
            jobTitle: values.jobTitle,
            capitalAvailableInvest: values.capitalAvailableInvest,
            comments: values.comments
          })
        });

        if (!response.ok) {
          const errorData = await response.json().catch(() => ({}));
          console.error('Franchise endpoint error:', errorData);
          throw new Error('Failed to send franchise request');
        }

        const responseData = await response.json();
        console.log('Franchise endpoint response:', responseData);
      } else {
        try {
          const eventParams = {
            email: values.email,
            phone: values.phone,
            crmOrClassic: "classic",
            page_location: window.location.href,
            page_title: document.title
          };
          await eventFormSubmission(url, "lead", eventParams);
          console.log('GA Event successfully sent');
        } catch (analyticsError) {
          console.error('GA Event failed:', analyticsError);
        }

        setCookie("converted", "step_1", {
          path: "/",
          maxAge: 86400 * 7 // 7 days
        });

        // For regular forms, send to both endpoints
        await submitMultiple([
          {
            url: EMAIL_URL,
            method: "POST",
            data: baseData
          },
          {
            url: CRM_URL,
            method: "POST",
            data: baseData
          }
        ]);

        // Show success modal
        if (parentSetShowModal) {
          parentSetShowModal(true);
        } else {
          setShowModal(true);
        }

        // Reset form
        form.resetFields();

        // Restore showroom selection if needed
        if (values.showroom) {
          form.setFieldsValue({
            showroom: values.showroom,
            tenantId: values.tenantId
          });
        }

        // Send Facebook conversion event
        const fbResponse = await fetch('/api/crm/fb-conversion', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            first_name: values.full_name,
            email: values.email,
            phone: values.phone,
            event_time: Date.now()
          })
        });
        
        const fbResult = await fbResponse.json();
        console.log('Facebook conversion response:', fbResult);
      }
    } catch (error) {
      console.error('Form submission error:', error);
    } finally {
      setLoading(false);
    }
  };

  // Determine if this is a franchise form
  const isFranchiseForm = dispatchDestination === 'franchise';

  const handleCloseModal = () => {
    if (parentSetShowModal) {
      parentSetShowModal(false);
    } else {
      setShowModal(false);
    }
  };

  return (
    <>
      {variant === "large" ? (
        <FormConsultationLarge
          handleSubmit={handleSubmit}
          loading={loading}
          form={form}
          theme={theme}
          referralCode={referralCode}
          showModal={parentShowModal || showModal}
          setShowModal={parentSetShowModal || setShowModal}
          submitButton={submitButton}
          dispatchDestination={dispatchDestination}
          formId={formId}
          darkLabels={darkLabels}
          isFranchiseForm={isFranchiseForm}
        />
      ) : (
        <FormConsultationSmall
          handleSubmit={handleSubmit}
          loading={loading}
          form={form}
          theme={theme}
          referralCode={referralCode}
          showModal={parentShowModal || showModal}
          setShowModal={parentSetShowModal || setShowModal}
          submitButton={submitButton}
          dispatchDestination={dispatchDestination}
          formId={formId}
          darkLabels={darkLabels}
          isFranchiseForm={isFranchiseForm}
        />
      )}
      <ModalContactSuccess 
        visible={parentShowModal || showModal} 
        onRequestClose={handleCloseModal}
      />
    </>
  );
};

FormConsultation.propTypes = {
  theme: PropTypes.object,
  variant: PropTypes.string,
  referralCode: PropTypes.string,
  submitButton: PropTypes.string,
  dispatchDestination: PropTypes.string,
  id: PropTypes.string,
  darkLabels: PropTypes.bool,
  handleSubmit: PropTypes.func,
  loading: PropTypes.bool,
  form: PropTypes.object,
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
};

export default withTheme(FormConsultation);
